/*
 Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential

 */
var Language = (function () {
    'use strict';

    var firstInit = true;

    /* ----- Init Language -------------------------------------------------------------------------------------------- */
    function Init(selector) {
        var
            lang = selector || localStorage.getItem('rca4_lang') || AppData.settings.lang || 'en',
            pack = AppData.languages || [];

        let lang_exists = AppData.translations.find(function(item){
            return item.translation_id == lang;
        });

        if (lang_exists == undefined) {
            lang = AppData.settings.lang || AppData.translations[0].translation_id;
        }

        localStorage.setItem('rca4_lang', lang);
        localState.lang = lang;

        $('html').attr('lang', lang);
        /* Loop Languge Pack */
        if (lang in pack) {
            /* Set Language Strings */
            $.each(pack[lang], function (key, val) {
                var $el = $('[data-lang="' + key + '"]');
                if ($el.is('input[type=text], input[type=password], input[type=search], textarea')) {
                    $el.attr('placeholder', val);
                } else {
                    $el.html(val);
                }
                $el = null;
            });
        }
        lang = pack = null;
        /* Rebuild Agenda Date Selector */

        if (!firstInit) {

            if ('Agenda' in window && !('Init' in Agenda)) {
                Agenda.BuildFromSource();
            }
            if ('Speakers' in window) {
                Speakers.BuildFromSource();
            }
            if ('Docs' in window) {
                Docs.BuildFromSource();
            }
            if ('Useful' in window) {
                Useful.BuildFromSource();
            }
            if ('Bookings' in window && Bookings.BuildFromSourceClient) {
                Bookings.BuildFromSourceClient();
            }

            if ('Surveys' in window) {
                Surveys.BuildFromSource();
            }

            if ('Attendees' in window) {
                Attendees.BuildFromSource();
            }

            if ('Profile' in window) {
                Profile.Init();
            }
            if ('Settings' in window) {
                Settings.Init();
            }

            if ('Modulsettings' in window) {
                Modulsettings.Init();
            }

            if ('Navigation' in window && typeof Navigation.BuildFromSource != "undefined") {
                Navigation.BuildFromSource();
            }

            if ('Translator' in window) {
                Translator.Init();
            }

            if ('Soundchannels' in window) {
                Soundchannels.Init();
            }
            if ('Profile' in window) {
                Profile.buildLanguages();
            }

            if ('Texts' in window) {
                Texts.Init();
            }
        }

        Layout.hide("issues-details");
        Layout.hide("docs-details");
        Layout.hide("useful-details");

        firstInit = false;
    }

    function buildFromSource() {
        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: "getLanguages"
            },
            success: function (response) {
                AppData.languages = response;

                let
                    lang = localStorage.getItem('rca4_lang') || AppData.settings.lang || 'en',
                    pack = AppData.languages || [];

                if (lang in pack) {
                    $.each(pack[lang], function (key, val) {
                        let $el = $('[data-lang="' + key + '"]');
                        if ($el.is('input[type=text], input[type=password], input[type=search], textarea')) {
                            $el.attr('placeholder', val);
                        } else {
                            $el.html(val);
                        }
                        $el = null;
                    });
                }
            }
        });
    }

    /* ----- Get Language Label --------------------------------------------------------------------------------------- */
    function getItem(item) {
        if (AppData.languages && localState.lang in AppData.languages) {
            return AppData.languages[localState.lang][item] || item;
        }
        return item;
    }

    function getTranslation(item, lang, field) {
        if (item == undefined) {
            return "";
        }

        if (item[lang] !== undefined) {
            if (field !== undefined) {
                return item[lang][field] === undefined ? item : item[lang][field];
            } else {
                return item[lang];
            }
        } else {
            var fallback;
            for (var i in item) {
                if (field !== undefined) {
                    fallback = item[i][field];
                } else {
                    fallback = item[i];
                }
                break;
            }

            return fallback === undefined ? item : fallback;
        }
    }

    function checkTranslation() {
        $.ajax({
            method: 'post',
            url: 'api/',
            data: {
                do: 'getTranslations'
            },
            success: function (response) {
                AppData.translations = response;

                let lang_exists = AppData.translations.find(function(item){
                    return item.translation_id == localStorage.getItem('rca4_lang');
                });

                if (lang_exists == undefined) {
                    localStorage.setItem('rca4_lang', AppData.settings.lang);
                    Init(AppData.settings.lang);
                }
            }
        });
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        getItem: getItem,
        getTranslation: getTranslation,
        checkTranslation: checkTranslation,
        buildFromSource: buildFromSource
    };
})();
