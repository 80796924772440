(() => {
    let isKiosk = false;
    if (typeof window.webkit !== "undefined") {
        isKiosk = true;
    }

    let callbacks = {
        'camera': [],
        'qr': [],
        'one_time_callback': null
    };
    window.Kiosk = class Kiosk {
        static kiosk_supported() {
            return isKiosk;
        }

        static setKiosk(state) {
            isKiosk = state;
        }

        static camera(one_time_callback = null) {

            callbacks.one_time_callback = one_time_callback;

            if (isKiosk) {
                window.webkit.messageHandlers.showActivity.postMessage("camera");
            } else {
                console.log('KIOSK CAMERA CALLED');
            }
        }

        static readqr() {
            if (isKiosk) {
                window.webkit.messageHandlers.showActivity.postMessage("qr");
            } else {
                console.log('KIOSK READ QR CALLED');
            }
        }

        static addCameraListener(type, callback) {
            callbacks[type].push(callback);
        }

        static __receiveCameraResponse(raw) {

            if (callbacks.one_time_callback !== null) {
                callbacks.one_time_callback(raw);
                callbacks.one_time_callback = null;

            } else {
                callbacks.camera.forEach((item) => {
                    item(raw);
                });
            }
        }

        static __receiveQRResponse(code) {
            callbacks.qr.forEach((item) => {
                item(code);
            });
        }
    };
})();