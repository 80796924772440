/*
 Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential

 */
var Socket = (function () {
    'use strict';
    /* ----- Declare Valiables ---------------------------------------------------------------------------------------- */
    var
        socketId = "",
        timerInit = 0,
        timerPing = 0,
        timerPong = 0,
        timerConnection = 0,
        url = new URL(location.href),
        socket,
        protocol = (url.protocol === 'https:') ? 'wss' : 'ws',
        hostname = url.hostname,
        //port = /(rentit|eventcloud)\.hu/.test(url.hostname) ? '443' : '5019',
        port,
        tryToReconnect = true;
    let reconnect_count = 0;
    let max_reconnect = 1;
    let silent = false;
    /* ----- Overwrite socket address --------------------------------------------------------------------------------- */
    //hostname = /(rentit|eventcloud)\.hu/.test(url.hostname) ? 'rca4dev.eventcloud.hu' : hostname;
    /* ----- WebSocket Init ------------------------------------------------------------------------------------------- */
    function Init(force) {

        if (reconnect_count == max_reconnect && force != true) {
            reconnect_count = 0;
            clearTimeout(timerInit);
            closeSocket();
            if (typeof Layout !== 'undefined') {
                Layout.hide('connecting');
            }
        } else {
            hostname = AppData.settings.socket_url;
            port = (url.protocol === 'https:') ? '443' : AppData.settings.socket_port;

            var host = protocol + '://' + hostname + ':' + port + '/?type=' + socketUserType + '&ch=' + (localState.currentChannel || 'ch0');
            clearTimeout(timerInit);
            clearTimeout(timerPing);
            clearTimeout(timerPong);
            timerConnection = setTimeout(showConnectionLayout, 600);

            try {
                socket = new WebSocket(host);
                socket.addEventListener('open', onOpen);
                socket.addEventListener('message', onMessage);
                socket.addEventListener('close', onClose);
                socket.addEventListener('error', onError);
            } catch (err) {
                console.error('Socket Init Error: ' + err);
                /*if ( tryToReconnect ) {
                 timerInit = setTimeout( Init, 5000 );
                 }*/
            }
        }

        reconnect_count++;
    }

    /* ----- Show Coneection ------------------------------------------------------------------------------------------ */
    function showConnectionLayout() {
        if (typeof Layout !== 'undefined' && silent !== true) {
            Layout.show('connecting');
        }
    }

    var checkquiz;

    /* ----- Event: Socket Open --------------------------------------------------------------------------------------- */
    function onOpen() {
        var
            profile = AppData.profile || {},
            server = AppData.server || {};
        if (this.readyState === 1) {
            console.info('Connected to Socket Server. (' + this.readyState + ' ch: '+ localState.currentChannel +')');
            Send('wsAuth', {
                id: profile.id || null,
                client: socketUserType || null,
                channel: localState.currentChannel || ['ch0'],
                group: profile.group || null,
                ip_addr: server.client_addr || null,
                site_id: AppData.settings.site_id
            });
            clearTimeout(timerConnection);
            clearTimeout(timerInit);
            clearTimeout(timerPing);
            clearTimeout(timerPong);
            timerPing = setTimeout(ping, 10000);
            if (typeof Layout !== 'undefined') {
                Layout.hide('connecting');
            }

            $("#landing header .socket_indicator").addClass("connected");
            $(".fg_reload .reload").addClass("hidden");
            if (typeof Quiz != 'undefined') {
                checkquiz = setInterval(function () {
                    if (Quiz.Init == undefined) {
                        clearInterval(checkquiz);
                        if (typeof Quiz.showShownQuiz == "function") {
                            Quiz.showShownQuiz(true);
                        }
                    }
                }, 200);
            }
        }
        profile = server = null;
    }

    /* ----- Event: Socket Close -------------------------------------------------------------------------------------- */
    function onClose() {
        if (this.readyState === 3) {
            console.info('Socket Closed.');
            showConnectionLayout();
            clearTimeout(timerInit);
            clearTimeout(timerPing);
            clearTimeout(timerPong);
            if (tryToReconnect && reconnect_count <= max_reconnect) {
                timerInit = setTimeout(Init, 1000);
            }

            $("#landing header .socket_indicator").removeClass("connected");
            $(".fg_reload .reload").removeClass("hidden");
        }
    }

    /* ----- Event: Socket Error -------------------------------------------------------------------------------------- */
    function onError() {
        console.log('socket error');
        $("#landing header .socket_indicator").removeClass("connected");
        $(".fg_reload .reload").removeClass("hidden");
        /*clearTimeout( timerConnection, timerInit, timerPing, timerPong );
         console.info( 'Socket Error.' );

         if ( tryToReconnect ) {
         timerInit = setTimeout( Init, 1000 );
         }*/
    }

    /* ----- Send Ping to Server -------------------------------------------------------------------------------------- */
    function ping() {
        clearTimeout(timerPing);
        clearTimeout(timerPong);
        timerPong = setTimeout(timedOut, 15000);
        if (socket && socket.readyState === 1) {
            socket.send('ping');
        }
    }

    /* ----- Event: Msg Receive --------------------------------------------------------------------------------------- */
    function onMessage(msg) {
        if (msg.data === 'pong') {
            clearTimeout(timerPong);
            clearTimeout(timerPing);
            timerPing = setTimeout(ping, 10000);
            return true;
        }

        try {
            var res = JSON.parse(msg.data);

            if (res.newConnectionSetup === true) {
                socketId = res.socketID;
                return;
            }

            if (res && res.fn && res.data !== undefined) {
                var
                    context = window,
                    namespaces = res.fn.split("."),
                    func = namespaces.pop(),
                    data;

                data = res.data;

                for (var i = 0; i < namespaces.length; i++) {
                    context = context[namespaces[i]];
                }
                if (typeof context[func] === "function") {
                    context[func].apply(null, [data]);
                }
            }
        } catch (err) {
            console.info('Socket Receive Error: ' + err);
        }
    }

    /* ----- Send Msg ------------------------------------------------------------------------------------------------- */
    function Send(fn, params = '', filters) {
        if (!fn) {
            console.error('Msg Sent Error: No command.');
            return;
        }
        try {
            let filter_obj = (typeof filters === 'object') ? filters || {} : {};
            filter_obj.site_id = AppData.settings.site_id;

            var msg = JSON.stringify({
                type: 'function',
                fn: fn,
                data: params,
                filters: filter_obj,
                secretKey: 'RentIT2017!SecretSocket',
                senderSocketID: socketId
            });
        } catch (err) {
            console.error('Msg Data is not declared or not JSON format.');
        }
        try {
            socket.send(msg);
            // console.log( 'Msg Sent: ' + msg );
        } catch (err) {
            console.error('Msg Send Error: ' + err);
        }
    }

    function timedOut() {
        console.log('Connection timeout.');
        socket.close();
        $("#landing header .socket_indicator").removeClass("connected");
        $(".fg_reload .reload").removeClass("hidden");
        //forceReload();
        /*showConnectionLayout();
         clearTimeout( timerInit, timerPing, timerPong );
         socket.close();*/
    }

    /* ----- Close Socket --------------------------------------------------------------------------------------------- */
    function stop() {
        //socket.close();
        console.log('stop called');
        tryToReconnect = false;
    }

    function closeSocket(forced) {
        console.log('closesocket called');
        socket.close();
        tryToReconnect = false;
        $("#landing header .socket_indicator").removeClass("connected");

        $(".fg_reload .reload").removeClass("hidden");

        let to = AppData.settings.socket_reconnect_time || 60;
        to = parseInt(to);
        if (to < 2 || isNaN(to)) {
            to = 2;
        }

        if (forced != true) {
            setTimeout(function () {
                reconnect_count = 0;
                tryToReconnect = true;
                silent = true;
                Init();
            }, to * 1000);
        } else {
            reconnect_count = 0;
            tryToReconnect = true;
            silent = true;
            Init();
        }
    }

    function getReadyState() {
        return socket.readyState;
    }

    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        Send: Send,
        stop: stop,
        closeSocket: closeSocket,
        readyState: getReadyState
    };
})();
