/*
 Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential

 */
'use strict';
/* ----- Global Variables ------------------------------------------------------------------------------------------- */
var
    AppData = {
        software: {},
        settings: {},
        navigation: {},
        languages: {},
        welcome: {},
        server: {},
        channels: {},
        agenda: {},
        speakers: {},
        attendees: {},
        rooms: {},
        issues: {}
    },
    cpus = window.navigator.hardwareConcurrency || 4,
    iosversion = iosVersion(),
    isLowEndDevice =
        (navigator.userAgent.toLowerCase().indexOf("android") > -1 && cpus < 8) ||
        (!iosversion && cpus < 4) || (iosversion && iosversion < 10)
            ? true : false,
    socketUserType = localStorage.getItem('socketUserType') || $('meta[name=utype]').attr("content"),
    localState = JSON.parse(sessionStorage.getItem('lastState.' + socketUserType)) || {},
    url_rewrite = (typeof hybrid !== 'undefined') ? hybrid.host : '';

var user_fields = [
    {label: "Username", name: "username", type: "text"},
    {label: "Phone", name: "phone", type: "text"},
    {label: "Password", name: "password", type: "password"},
    {label: "Email", name: "email", type: "text"},
    {label: "Name", name: "name", type: "text"},
    {label: "First name", name: "first_name", type: "text"},
    {label: "Last name", name: "last_name", type: "text"},
    {label: "Company", name: "company", type: "text"}
];

var registration_field_types = [
    {label: "Text", type: "text"},
    {label: "Textarea", type: "textarea"},
    {label: "Radio button", type: "radio"},
    {label: "Checkbox", type: "checkbox"},
    {label: "Select", type: "select"}
];

var vConsole;

/* Fix the Viewport */
Viewport.Init();
/* ----- Check Global Redirection ----------------------------------------------------------------------------------- */
$(document).on('ajaxComplete', function (e, xhr) {
    try {
        data = JSON.parse(xhr.responseText);
        if (data && 'redirect' in data) {
            $('main').attr('class', null).addClass('hidden');
            //window.stop();
            setTimeout(function () {
                location.replace(data.redirect);
            }, 250);
        }
    } catch (err) {
    }
});
/* ----- Hold Rready while Load Reasources -------------------------------------------------------------------------- */
//$.holdReady(true);
$.post(url_rewrite + "api/?v="+Math.random(), {
    do: 'getConfig'
}, _ajaxReadyGetConfig, 'json');

let waitForReady;
let is_ready = {
    config: false,
    html: false
};

function _ajaxReadyGetConfig(data) {
    if (data && !('redirect' in data)) {
        AppData = data || [];
        if (localState != undefined && AppData.profile != undefined) {
            if (localState.currentChannel == undefined) {
                localStorage.setItem('rca4_current_channel', AppData.profile.default_channel);
            }
        }
        is_ready.config = true;
        //$.holdReady(false);
    }
}

/* ----- Process on Document Ready ---------------------------------------------------------------------------------- */
function sharedReady() {
    /* Init WebSocket */
    Socket.Init();

    let lang_exists = AppData.translations.find(function(item){
        return item.translation_id == localStorage.getItem('rca4_lang');
    });

    if (lang_exists == undefined) {
        localStorage.setItem('rca4_lang', AppData.settings.lang);
    }

    localState.lang = localStorage.getItem('rca4_lang') || AppData.settings.lang || 'en';
    /* Toggle animation-reduce */
    $(document.body).toggleClass('animation-reduce', isLowEndDevice);

    /* IOS Standalone Status Bar */
    if ("standalone" in window.navigator && window.navigator.standalone) {
        $(document.documentElement).addClass('ios-status-bar');
        /* ISO 11 Hack*/
        if (
            Math.min(screen.width, screen.height) !== Math.min(window.innerWidth, window.innerHeight) ||
            Math.max(screen.width, screen.height) !== Math.max(window.innerWidth, window.innerHeight)
        ) {
            $(document.documentElement).addClass('ios11');
        }
    }
    /* IOS No Sleep js */
    if ("standalone" in window.navigator && window.navigator.standalone) {
        /* No Sleep Hasck */
        var noSleep = new NoSleep();
        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);
        console.log('auto nosleep activated');
        noSleep.enable();
    }
}

/* ----- Save Last State -------------------------------------------------------------------------------------------- */
$(window).on('unload', function () {
    Layout.hide('connecting');
    Layout.hide('updating');
    Layout.hide('offline');
    sessionStorage.setItem('lastState.' + socketUserType, JSON.stringify(localState));
});
/* ----- Start Hearthbeat ------------------------------------------------------------------------------------------- */
setInterval(function () {

    let currentLayout = localState.layout;
    if (Array.isArray(localState.layout)) {
        currentLayout = localState.layout.join(" | ");
    }

    $.post('api/', {
        do: 'heartbeat',
        layout: currentLayout
    }, function (data) {
        if (data.l != undefined) {
            forceLogOut();
        }
    });
}, (Math.floor(Math.random() * 20) + 20) * 1000);

/* ----- Force Page Reload ------------------------------------------------------------------------------------------ */
function forceReload() {
    Layout.hide('offline');
    Layout.show('updating');
    setTimeout(function () {
        location.reload();
    }, (Math.floor(Math.random() * 15) + 5) * 100);
}

function checkUpdate() {
    if (typeof window.webkit !== "undefined") {
        window.webkit.messageHandlers.showActivity.postMessage('checkUpdate');
    }
}

function backToEvents() {
    if (typeof window.webkit !== "undefined") {
        window.webkit.messageHandlers.showActivity.postMessage('backToEvents');
    }
}

function destroyvConsole() {
    vConsole.destroy();
}

function initvConsole() {
    vConsole = new VConsole();
}

/* ----- Detect IOS Version ----------------------------------------------------------------------------------------- */
function iosVersion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        //return [ parseInt( v[1], 10 ), parseInt( v[2], 10 ), parseInt( v[3] || 0, 10 ) ];
        return parseInt(v[1], 10);
    }
}

/* ----- Serialze Form Data to JSON --------------------------------------------------------------------------------- */
$.fn.serializeObject = function () {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function () {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    a = null;
    return o;
};

/* ----- String Canonicalization ------------------------------------------------------------------------------------ */
function strCanonical(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

/* ----- Global Error Handler --------------------------------------------------------------------------------------- */
window.onerror = function (msg, url, lineNo, columnNo, error) {
    var string = msg.toLowerCase();
    var substring = "script error";
    if (string.indexOf(substring) > -1) {
        alert('Script Error: See Browser Console for Detail');
    } else {
        var message = [
            'Message: ' + msg,
            'URL: ' + url,
            'Line: ' + lineNo,
            'Column: ' + columnNo,
            'Error object: ' + JSON.stringify(error)
        ].join(' ');
        // Send Error Msg to API
        $.post('api/', {
            do: 'jsErrorLog',
            entry: message
        });
        console.log(message);
    }
    return false;
};
/* ----- Online State ----------------------------------------------------------------------------------------------- */
$(window).on('online', function () {
    forceReload();
    //Socket.Init();
    Layout.hide('offline');
    console.log('online');
});
/* ----- Offline State ---------------------------------------------------------------------------------------------- */
$(window).on('offline', function () {
    Socket.stop();
    Layout.show('offline');
    console.log('offline');
});
/* ----- Prevent History Back --------------------------------------------------------------------------------------- */
if (!iosversion) {
    $(window)
        .one('load', function () {
            window.history.pushState({}, '');
        })
        .on('popstate', function (e) {
            window.history.pushState({}, '');
            if (!$('main.system:visible').length && Array.isArray(localState.layout)) {
                Layout.hide(localState.layout.pop());
            }
        });
}

/* ---- Set request on fields by language ----------------------------------------------------------------------------*/
function setReqOnFieldsByLang(id, language_fields) {
    var $self = $('#' + id + '-details'),
        field;

    $.each(language_fields, function(i,e) {
        $.each(AppData.translations, function(il,el) {
            field = $self.find('[name="' + e.name + '[' + el.translation_id + ']"]');
            if(field.attr("required") !== undefined && el.active === false) {
                field.removeAttr('required');
            }
        });
    });
}

function sendUpdate(module, id_field, id_value, callback, data, callback_param, only_callback) {
    Socket.Send("updateModifiedContent", {
        module: module,
        id_field: id_field,
        id_value: id_value,
        updated_content: data,
        callback: callback,
        callback_params: callback_param,
        only_callback: only_callback
    });
}

const clocks = document.getElementsByClassName("clock");
function updateClocks() {
    for (let clock of clocks) {
        let timezone = clock.dataset.timezone;
        let city = clock.dataset.city;
        let time = new Date().toLocaleTimeString("en-US", {
            hour: '2-digit',
            minute:'2-digit',
            second:'2-digit',
            timeZone: timezone
        });
        clock.innerHTML = '<b>'+time +'</b><br><i>'+city+'</i>';

    }
}
// Update every minute:
//setInterval(updateClocks, 1000);
//updateClocks();

let timer = function() {
    this.countdown = 0;
    this.act_time = 0;
    this.timer = 0;
    this.direction = 1;
    this.timerobj = 0;
    this.start_time = 0;
    this.milliseconds = 0;
    this.eventName;
    this.callback_at;
    this.callback;
    this.format;

    var _that = this;

    this.init = function(timerobj, from, eventname, callback_at, callback) {
        _that.timerobj = timerobj;
        _that.start_time = from;
        _that.act_time = 0;
        _that.eventName = eventname;
        _that.callback_at = callback_at;
        _that.callback = callback;
        _that.format = "%M:%S";
        clearTimeout(_that.countdown);

        if (from > 0) {
            _that.act_time = from;
            _that.timerobj.html(_that.getFormatted(false));
            _that.direction = 0;
        } else {
            _that.timerobj.html(_that.getFormatted(true));
        }
    };

    this.setFormat = function (format) {
        _that.format = format;
    };

    this.getFormatted = function(zero) {
        let days = Math.floor(_that.act_time / 24 / 60 / 60);
        let hoursLeft = Math.floor((_that.act_time) - (days * 86400));
        let hours = Math.floor(hoursLeft / 3600);
        let minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
        let minutes = Math.floor(minutesLeft / 60);
        let seconds = _that.act_time % 60;

        let formatted = _that.format;
        formatted = formatted.replace("%D", zero ? "0" : days);
        formatted = formatted.replace("%H", zero ? "00" : _that.pad(hours));
        formatted = formatted.replace("%M", zero ? "00" : _that.pad(minutes));
        formatted = formatted.replace("%S", zero ? "00" : _that.pad(seconds));

        return formatted;
    }

    this.pad = function(n) {
        return (n < 10 ? "0" + n : n);
    }

    this.start = function() {
        _that.interval();
    };

    this.interval = function() {
        _that.countdown = setTimeout(function() {

            let ended = false;

            if (_that.direction == 1) {
                _that.act_time++;
                _that.interval();
            } else {
                if (_that.act_time <= 0) {
                    _that.act_time = 0;
                    clearTimeout(_that.countdown);
                    _that.timerobj.html(_that.getFormatted(true));
                    if (_that.eventName != undefined) {
                        $(document).trigger(_that.eventName);
                    }

                    if (_that.callback != undefined) {
                        _that.callback();
                    }

                    ended = true;
                } else {
                    _that.interval();
                }
                _that.act_time--;
            }

            if (_that.callback != undefined && _that.callback_at != undefined && (_that.act_time - 1) > 0 && (_that.act_time - 1) % _that.callback_at == 0) {
                _that.callback();
            }

            if (!ended) {
                _that.timerobj.html(_that.getFormatted(false));
            }

        }, 1000);
    };

    this.pause = function() {
        clearTimeout(_that.countdown);
    };

    this.resume = function() {
        _that.interval();
    };

    this.stop = function() {
        clearTimeout(_that.countdown);

        if (_that.start_time > 0) {
            _that.act_time = _that.start_time;
            _that.timerobj.html(_that.getFormatted(false));
            _that.direction = 0;
        } else {
            _that.timerobj.html(_that.getFormatted(true));
        }
    }
};

let cd_timer;

function showCountDown(show, position) {

    let cd_container = $("#landing .countdown_bc");
    cd_container.removeClass (function (index, className) {
        return (className.match (/(^|\s)cdown_\S+/g) || []).join(' ');
    });

    cd_container.addClass(position);

    if (show) {

        let cd_bgcolor = hexToRgb("#FFFFFF");
        if ('cdown_bgcolor' in AppData.modulsettings) {
            cd_bgcolor = hexToRgb(AppData.modulsettings.cdown_bgcolor);
        }

        let cd_bgOpacity = 1;
        if ('cdown_bgopacity' in AppData.modulsettings) {
            cd_bgOpacity = parseInt(AppData.modulsettings.cdown_bgopacity) / 100;
        }

        let clock_container = $("#landing .countdown_clock");

        clock_container.css("background-color", 'rgba(' + cd_bgcolor.r + ',' + cd_bgcolor.g + ',' + cd_bgcolor.b + ', '+cd_bgOpacity+')');
        clock_container.css("color", AppData.modulsettings.cdown_textcolor || "#000000");
        clock_container.css("font-size", AppData.modulsettings.cdown_fontsize + "px" || "14px");
        if (AppData.modulsettings.cdown_textshadow) {
            clock_container.addClass("shadow");
        } else {
            clock_container.removeClass("shadow");
        }

        let starttime = convertDateToUTC(new Date(AppData.modulsettings.target_time)).getTime() - convertDateToUTC(new Date()).getTime();

        if (cd_timer != undefined) {
            cd_timer.stop();
        } else {
            cd_timer = new timer();
        }

        if (starttime > 0) {
            clock_container.fadeIn();
        }

        cd_timer.init(clock_container, Math.round(starttime / 1000), undefined, undefined, function () {
            if (AppData.modulsettings.cdown_hideonzero) {
                $("#landing .countdown_clock").fadeOut();
            }
        });

        let format = "%D days, %H hours %M minutes %S seconds";
        if (AppData.modulsettings.target_time_format != undefined && AppData.modulsettings.target_time_format.hasOwnProperty(localState.lang)) {
            format = AppData.modulsettings.target_time_format[localState.lang];
        }
        cd_timer.setFormat(format);
        cd_timer.stop();
        cd_timer.start();

    } else {
        $("#landing .countdown_clock").hide();
        if (cd_timer != undefined) {
            cd_timer.stop();
        }
    }
}

function convertDateToUTC(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}

function decodeEntities(encodedString) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
}
function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function writeDbLog(action, note, table, module = "") {
    $.post("api/", {
        do: "writeDbLog",
        data: {'module': module, 'action': action, 'note': note, 'table': table}
    },function(data) {});
}

function setFavicon() {
    if ('favicon' in AppData.settings) {
        let fg = AppData.medias.find(function (item){
            return item.id == AppData.settings.favicon;
        });
        if (fg && fg.files[localState.lang].file != undefined) {
            $("#favicondynamic").attr("href", fg.files[localState.lang].file);
        }
    }
}